import { combineReducers } from "redux";
import users from '../containers/users/reducer';
import supports from '../containers/support/reducer';
import notification from '../api/reducer';

export default combineReducers({
  users,
  supports,
  notification
})
