import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const VideoFileOpen = (props) => {

  const [videoLinksId, setVideoLinks] = useState('');
  const [platform, setPlatform] = useState('');

  async function fetchDetails() {
    const userId = props.location.pathname.split('video/');
    const flag = props.location.search.split('?platform=');
    setPlatform(flag[1])
    setVideoLinks(userId[1]);
  }

  useEffect(() => {
    fetchDetails();
  }, []);

  return (
    <div className="fullScreen-video">
      <iframe id="thisIframe" width="100%" height="100%"
        src={`https://www.youtube.com/embed/${videoLinksId}?autoplay=1${platform ? '&mute=1' : ''}`}
        frameBorder="0" allow='autoplay; encrypted-media'></iframe>
    </div>
  )
}
export default withRouter(VideoFileOpen);
