const initData = {
  userSupport: null
}

export default function (state = initData, action = {}) {
  if (action.type === "SUPPORT_LIST_DATA") {
    const SupportR = action.data;
    return {
      ...state,
      userSupport: SupportR
    };
  } else {
    return {
      ...state,
    }
  }
}
