import React from 'react';
import logo from '../../assets/images/logo.svg';

const Header = (props) => {
  return (
    <div className='content-header'>
      <img src={logo} width="75" height="75" alt="logo" />
    </div>
  )
}
export default Header;
