import React from 'react';
import DashboardStructure from '../../components/shared/DashboardStructure/DashboardStructure';
import userIcon from '../../assets/images/users-image.svg';
import artistIcon from '../../assets/images/artists.svg';
import audioIcon from '../../assets/images/audio-engineer.svg';
import featuredIcon from '../../assets/images/featured-members.svg';
import newsIcon from '../../assets/images/news.svg';
import producerIcon from '../../assets/images/producers.svg';
import instumentIcon from '../../assets/images/instrumentalist.svg';
import songwriterIcon from '../../assets/images/songwriter.svg';
import bulletinIcon from '../../assets/images/bulletin-icon.svg'
import { Card, CardImg, CardText, CardBody, CardTitle, Button, FormGroup, Input } from 'reactstrap'
import LineChart from './LineChart';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { dashboardStatics, dashboardGraphData } from '../../api/admin'
import LoadingMask from "react-loadingmask";

// Chart data add from here

const more_details = 'More Details';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      selectedValue: 'weekly',
      dashboardData: null,
      graphData: null,
      graphLabels: null,
      graphDataset: null,
      graphLabelDates: null,
    };
  }

  getDashboardGraphData = (value = this.state.selectedValue) => {
    this.props.dashboardGraphData({ "filterType": value }).then(res => {
      const labels = [];
      const datasets = [];
      const labelDates = [];
      res.graphData.forEach(element => {
        labels.push(element.lable);
        labelDates.push(element.labelDate);
        if (element.amount !== null) {
          const newData = element.amount.substring(1);
          datasets.push(newData);
        } else {
          datasets.push(element.amount);
        }
      });
      this.setState({ graphLabels: labels, graphDataset: datasets, graphLabelDates: labelDates, graphData: res });
    });
  }

  handleChange = (e) => {
    this.setState({ selectedValue: e.target.value });
    this.getDashboardGraphData(e.target.value)
  }

  async componentDidMount() {
    this.setState({ loaded: true });
    const statics = await this.props.dashboardStatics();
    if (statics) {
      this.setState({ dashboardData: statics })
    }
    this.getDashboardGraphData();
    this.setState({ loaded: false })
  }

  moreDetails = (user_flag) => {
    if (user_flag === 'users') {
      this.props.history.push('/user-list');
    }else if (user_flag === 'headline') {
      this.props.history.push('/headline-management');
    } else if (user_flag === 'artist') {
      this.props.history.push('/user-list?search=artist');
    } else if (user_flag === 'producer') {
      this.props.history.push('/user-list?search=producer');
    } else if (user_flag === 'audio') {
      this.props.history.push('/user-list?search=audio');
    } else if (user_flag === 'instrument') {
      this.props.history.push('/user-list?search=instrument');
    } else if (user_flag === 'songwriter') {
      this.props.history.push('/user-list?search=songwriter')
    } else {
      this.props.history.push('/user-list?search=featured');
    }
  }

  render() {
    const { loaded, dashboardData, graphLabels, graphDataset, graphLabelDates, graphData } = this.state;
    const totalRevenue = graphData ? graphData.totalRevenue.substring(1) : '';
    const membershipRevenue = graphData ? graphData.membershipRevenue.substring(1) : '';
    const headlineRevenue = graphData ? graphData.headlineRevenue.substring(1) : '';
    const chartData = {
      labels: graphLabels,
      datasets: [
        {
          fill: false,
          lineTension: 0.5,
          backgroundColor: '#921950',
          borderColor: '#921950',
          borderWidth: 2,
          data: graphDataset,
          date: graphLabelDates
        }
      ]
    }
    return (
      <LoadingMask loading={loaded} text={'loading...'}>
        <DashboardStructure title="Dashboard" history={this.props.history}>
          {dashboardData && (
            <div className="card-wrap">
              <Card>
                <CardImg width="50" height="50" src={userIcon} alt="Total number of registered users" />
                <CardBody>
                  <CardTitle>{dashboardData[0] && dashboardData[0].registered_users}</CardTitle>
                  <CardText>Total number of registered users</CardText>
                  <Button className="more-detail" onClick={() => this.moreDetails('users')}>
                    {more_details}
                  </Button>
                </CardBody>
              </Card>
              <Card>
                <CardImg width="50" height="50" src={newsIcon} alt="Total number of registered headlines" />
                <CardBody>
                  <CardTitle>{dashboardData[0] && dashboardData[0].registered_headlines}</CardTitle>
                  <CardText>Total number of registered headlines</CardText>
                  <Button className="more-detail" onClick={() => this.moreDetails('headline')}>
                    {more_details}
                  </Button>
                </CardBody>
              </Card>
              <Card>
                <CardImg width="50" height="50" src={artistIcon} alt="Total number of Users" />
                <CardBody>
                  <CardTitle>{dashboardData[1] && dashboardData[1][0].role_id === 1 ? dashboardData[1][0].member_count : '0'}</CardTitle>
                  <CardText>Total number of Artists</CardText>
                  <Button className="more-detail" onClick={() => this.moreDetails('artist')}>
                    {more_details}
                  </Button>
                </CardBody>
              </Card>
              <Card>
                <CardImg width="50" height="50" src={producerIcon} alt="Total number of Users" />
                <CardBody>
                  <CardTitle>{dashboardData[1] && dashboardData[1][1].role_id === 2 ? dashboardData[1][1].member_count : '0'}</CardTitle>
                  <CardText>Total number of Producers</CardText>
                  <Button className="more-detail" onClick={() => this.moreDetails('producer')}>
                    {more_details}
                  </Button>
                </CardBody>
              </Card>
              <Card>
                <CardImg width="50" height="50" src={audioIcon} alt="Total number of Users" />
                <CardBody>
                  <CardTitle>{dashboardData[1] && dashboardData[1][3].role_id === 4 ? dashboardData[1][3].member_count : '0'}</CardTitle>
                  <CardText>Total number of Audio Engineers</CardText>
                  <Button className="more-detail" onClick={() => this.moreDetails('audio')}>
                    {more_details}
                  </Button>
                </CardBody>
              </Card>
              <Card>
                <CardImg width="50" height="50" src={instumentIcon} alt="Total number of Users" />
                <CardBody>
                  <CardTitle>{dashboardData[1] && dashboardData[1][2].role_id === 3 ? dashboardData[1][2].member_count : '0'}</CardTitle>
                  <CardText>Total number of Instrumentalists</CardText>
                  <Button className="more-detail" onClick={() => this.moreDetails('instrument')}>
                    {more_details}
                  </Button>
                </CardBody>
              </Card>
              <Card>
                <CardImg width="50" height="50" src={songwriterIcon} alt="Total number of Users" />
                <CardBody>
                  <CardTitle>{dashboardData[1] && dashboardData[1][4].role_id === 5 ? dashboardData[1][4].member_count : '0'}</CardTitle>
                  <CardText>Total number of Songwriters</CardText>
                  <Button className="more-detail" onClick={() => this.moreDetails('songwriter')}>
                    {more_details}
                  </Button>
                </CardBody>
              </Card>
              <Card>
                <CardImg width="50" height="50" src={featuredIcon} alt="Total number of Featured Members" />
                <CardBody>
                  <CardTitle>{dashboardData[0] && dashboardData[0].total_featured}</CardTitle>
                  <CardText>Total number of Featured Members</CardText>
                  <Button className="more-detail" onClick={() => this.moreDetails('featured')}>
                    {more_details}
                  </Button>
                </CardBody>
              </Card>
              <Card>
                <CardImg width="50" height="50" src={newsIcon} alt="Total number of News" />
                <CardBody>
                  <CardTitle>{dashboardData[0] && dashboardData[0].total_headline_count}</CardTitle>
                  <CardText>Total number of Featured Headlines</CardText>
                </CardBody>
              </Card>
              <Card>
                <CardImg width="50" height="50" src={bulletinIcon} alt="Total number of Bulletins" />
                <CardBody>
                  <CardTitle>{dashboardData[0] && dashboardData[0].total_bulletin_count}</CardTitle>
                  <CardText>Total number of Bulletins</CardText>
                  <Button className="more-detail" onClick={() => this.props.history.push('/bulletin-board')}>
                    {more_details}
                  </Button>
                </CardBody>
              </Card>
            </div>
          )}
          <div className="graph-wrap">
            <div className="total-revenue">
              <div className="left">
                <span className="title">Total Revenue</span>
                <strong className="revenue">
                  <sup>$</sup>
                  {totalRevenue}
                </strong>
              </div>
              <div className="right">
                <FormGroup>
                  <Input type="select" onChange={this.handleChange} value={this.state.selectedValue}>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </Input>
                </FormGroup>
              </div>
            </div>
            <div className="graph-box">
              <LineChart chartData={chartData} />
            </div>
            <div className="revenue-wrap">
              <div className="headlineplan">
                <span className="title">Total Revenue from Headline Plan</span>
                <strong className="revenue">
                  <sup>$</sup>
                  {headlineRevenue}
                </strong>
              </div>
              <div className="headlineplan">
                <span className="title">Total Revenue from Featured Member Status</span>
                <strong className="revenue">
                  <sup>$</sup>
                  {membershipRevenue}
                </strong>
              </div>
            </div>
          </div>
        </DashboardStructure>
      </LoadingMask>
    )
  }
}

const mapStateToProps = store => {
  return {
    admin: store.user
  }
}

export default connect(mapStateToProps, {
  dashboardStatics,
  dashboardGraphData
})(withRouter(Dashboard));
