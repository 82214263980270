import React from 'react';
import { FormGroup } from 'reactstrap';
import { Link } from 'react-router-dom';
import successIcon from '../../../assets/images/tick.svg';


const SuccessMessage = (props) => {
  return (
    <div className="success-message text-center">
      <figure className="success-icon">
        <img src={successIcon} width="70" height="70" alt="success icon" />
      </figure>
      <h3>Link sent</h3>
      <p className="sub-text">
        We have sent a reset password email to {props.email}. Please
        click the reset password link to set your password.
      </p>
      <FormGroup>
        <Link
          className="btn btn-primary"
          to="/login">
          Back to Sign In
          </Link>
      </FormGroup>
    </div>
  )
}
export default SuccessMessage;
