import React, { useState, useEffect } from 'react';
import {
  TabContent, TabPane, Nav, NavItem, NavLink,
  Breadcrumb, BreadcrumbItem
} from 'reactstrap';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import DashboardStructure from '../../../components/shared/DashboardStructure/DashboardStructure';
import classnames from 'classnames';
import Profile from './Profile';
import UploadedTracks from './UploadedTracks';
import Projects from './Projects';
import Plan from './Plan';
import './detailPage.scss';
import { userDetail, deleteAudioTrack, deleteProjects } from '../../../api/admin';
import LoadingMask from "react-loadingmask";
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Numbers } from '../../../constants/constant';
import ModalBox from '../../../components/shared/pageComponent/ModalBox';
import NotificationMessage from '../../../components/shared/pageComponent/NotificationMessage';

const UserlistingDetail = (props) => {

  const [userData, setUserData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isProjectNotificationOpen, setIsProjectNotificationOpen] = useState(false);
  const [projectId, setProjectId] = useState();
  const [audioLinkId, setAudioLinkId] = useState();


  async function fetchDetails() {
    const userId = props.location.pathname.split('/user-detail/');
    let data = await props.userDetail(userId[1]);
    if (data) {
      setUserData(data.data);
      setLoaded(false);
    }
    if (localStorage.getItem('from')) {
      setActiveTab('3')
    }
  }

  useEffect(() => {
    setLoaded(true);
    fetchDetails();
  }, []);

  const toggleTabs = tab => {
    localStorage.removeItem('from');
    if (activeTab !== tab) setActiveTab(tab);
  }

  const removeProject = (id) => {
    if (id) {
      setProjectId(id)
    }
    setIsProjectNotificationOpen(open => !open);
  }
  const notifyProject = async (a) => {
    setLoaded(true);
    const project = await props.deleteProjects(projectId);
    if (project && project.msg) {
      toast(project.msg);
      fetchDetails();
      setProjectId();
      setLoaded(false);
    } else {
      toast(project && project.data && project.data.msg, { type: 'error' });
      setProjectId();
      setLoaded(false);
    }
  }

  const removeAudioLink = (id) => {
    if (id) {
      setAudioLinkId(id)
    }
    setIsNotificationOpen(open => !open);
  }
  const notifyAudioLink = async (a) => {
    setLoaded(true);
    const audio = await props.deleteAudioTrack(audioLinkId);
    if (audio && audio.msg) {
      toast(audio.msg);
      fetchDetails();
      setAudioLinkId();
      setLoaded(false);
    } else {
      toast(audio && audio.data && audio.data.msg, { type: 'error' });
      setAudioLinkId();
      setLoaded(false);
    }
  }


  return (
    <LoadingMask loading={loaded} text={"loading..."}>
      <ToastContainer
        position="top-center"
        autoClose={Numbers.twoThou}
        transition={Zoom}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <DashboardStructure title="User Management" history={props.history}>
        <div className="header-section">
          <div className="title-wrap">
            <h3>User Details</h3>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/user-list">User Listings</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>User Details</BreadcrumbItem>
            </Breadcrumb>
          </div>
          <div className="total-spent">
            <strong className="revenue">
              {userData.user_plans !== undefined ? userData.user_plans.totalSpent : ''}
            </strong>
            <small>Total Spent</small>
          </div>
        </div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => { toggleTabs('1'); }}>
              Profile Information
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => { toggleTabs('2'); }}>
              Uploaded Tracks
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '3' })}
              onClick={() => { toggleTabs('3'); }}>
              Projects
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '4' })}
              onClick={() => { toggleTabs('4'); }}>
              Plan
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1" className="white-bg">
            {/* Data coming from profile.jsx */}
            {userData && <Profile user={userData} />}
          </TabPane>
          <TabPane tabId="2">
            {/* Data coming from UploadedTracks.jsx */}
            {userData && <UploadedTracks uploadedTracks={userData && userData.audio_links} removeAudioLink={removeAudioLink} />}
          </TabPane>
          <TabPane tabId="3">
            {/* Data coming from Projects.jsx */}
            {userData && <Projects user={userData} removeProject={removeProject} />}
          </TabPane>
          <TabPane tabId="4">
            {/* Data coming from plan.jsx */}
            {userData && <Plan userPlans={userData.user_plans} />}
          </TabPane>
        </TabContent>
        <ModalBox
          isOpen={isProjectNotificationOpen}
          ModalTitle="Filter"
          toggle={removeProject}
          className="modal-small">
          <NotificationMessage
            openNot={notifyProject}
            closeModal={removeProject}
            userAction="remove"
            item="project"
          />
        </ModalBox>
        <ModalBox
          isOpen={isNotificationOpen}
          ModalTitle="Filter"
          toggle={removeAudioLink}
          className="modal-small">
          <NotificationMessage
            openNot={notifyAudioLink}
            closeModal={removeAudioLink}
            userAction="remove"
            item="track"
          />
        </ModalBox>
      </DashboardStructure>
    </LoadingMask>
  )
}

const mapStateToProps = store => {
  return {
    admin: store.user
  }
}

export default connect(mapStateToProps, {
  userDetail,
  deleteAudioTrack,
  deleteProjects
})(withRouter(UserlistingDetail));
