export const Numbers = {
  zero: 0,
  one: 1,
  two: 2,
  three: 3,
  hundred: 500,
  twoHun: 200,
  twoThou: 2000,
  tw24: 24,
  thrt: 30,
  ten: 10
}

export const Text = {
  formControl: 'form-control select',
  featured: 'Featured',
  non_featured: 'Non-Featured'

}
