import React from "react";
import * as Solid from "@heroicons/react/24/solid";
import PreloadImage from "react-preload-image";
import "./style.scss";

export const ModalPartners = ({ playback, hideModal }) => {
  return (
    <div className="container-partners">
      <div className="body">
        <button className="close-modal" onClick={hideModal}>
          <Solid.XMarkIcon />
        </button>
        <h2 className="title">Partners of Song ({playback.media.title})</h2>
        <div className="list">
          {playback.users.map((user, index) => {
            return (
              <div key={index} className="partner">
                <div className="cover">
                  {user.profile_image ? (
                    <PreloadImage
                      className="image"
                      src={user.profile_image}
                      alt={user.name}
                      lazy
                    />
                  ) : (
                    <span className="alias">
                      {user.name.charAt(0).toUpperCase()}
                    </span>
                  )}
                </div>
                <div className="info">
                  <h3 className="title">{user.name}</h3>
                  <p className="description">
                    {user.city}-{user.state}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
