import axios from 'axios';
import "./interceptors";

export function getProfile() {
  return dispatch => {
    return axios.get(`users/profile`).then(res => {
      dispatch({
        type: "ACTION_GET_PROFILE",
        data: res.data
      })
      return res.data
    }).catch(err => { return false })
  }
}

export function getUsersTalents() {
  return dispatch => {
    return axios.get(`api/v1/roles`).then(res => {
      dispatch({
        type: "GET_USER_TALENTS",
        data: res.data
      });
      return res.data
    }).catch(err => {
      return false
    })
  }
}

export function getUsersGenre() {
  return dispatch => {
    return axios.get(`api/v1/genres/`).then(res => {
      dispatch({
        type: "GET_USER_GENRE",
        data: res.data
      });
      return res.data
    }).catch(err => {
      return false
    })
  }
}

export default {
  getProfile
}
