import React from 'react';
import {
  Card, Button, CardTitle, CardText,
} from 'reactstrap';
import { dateFormat, timeFormat } from '../../../utils/method';
import { withRouter } from 'react-router-dom';

const Projects = (props) => {

  const { user } = props;
  const navigateOnDetailsPage = (project) => {
    localStorage.setItem('from', 'project');
    props.history.push(`/project-detail/${project.id}`)
  }

  return (
    <div className="white-bg-row">
      {user && user.projects && user.projects.length ? user.projects.map((value, index) => <Card className="past" body key={index}>
        <CardTitle onClick={() => navigateOnDetailsPage(value)}>
          <strong>
            {value.title}
          </strong>
          <span className={`card-status ${value.type === 'upcoming' ? 'new' : ''}`}>{value.type}</span>
        </CardTitle>
        <span className={`post-time `} onClick={() => navigateOnDetailsPage(value)}>
          {dateFormat(value.createdAt)}  |  {timeFormat(value.createdAt)}
        </span>
        <CardText onClick={() => navigateOnDetailsPage(value)}>{value.description}</CardText>
        <div className="action-wrap  text-right">
          <Button className="remove-btn" onClick={() => props.removeProject(value.id)}>Remove</Button>
        </div>
      </Card>) : <span className="block-label">No projects added yet!</span>}
    </div>
  )
}
export default withRouter(Projects);
