import React from 'react';
import {
  Media
} from 'reactstrap';
import DummyIcon from '../../../assets/images/aud.jpeg';
import moment from 'moment';

const UploadedTracks = (props) => {

  return (
    <div className="white-bg-row">
      {props && props.uploadedTracks && props.uploadedTracks.length ? props.uploadedTracks.map((value, key) =>
        <Media key={key}>
          <Media left top href="#">
            <Media object src={value.thumbnail ? value.thumbnail : DummyIcon} alt="Generic placeholder image" />
          </Media>
          <Media body>
            <Media heading>
              {value.title ? value.title : 'N/A'}
            </Media>
            <small className="time">{value.duration ? moment.utc(parseInt(value.duration)).format('mm:ss') : 'N/A'}</small>
            <span className="remove-btn" onClick={() => props.removeAudioLink(value.id)}>Remove</span>
          </Media>
        </Media>
      ) : <span className="block-label">No tracks uploaded yet!</span>}
    </div>
  )
}
export default UploadedTracks;
