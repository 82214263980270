import React from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from './routes'
import { connect } from 'react-redux';
import PrivateRoute from './routes/privateRoutes'
import "react-loadingmask/dist/react-loadingmask.css";

class App extends React.Component {
  render() {
    return (
      <Switch>
        {
          routes.map(({ path, exact, component: C, authentication, ...rest }) => {
            if (!authentication) {
              return (<Route
                key={path}
                path={path}
                exact={exact}
                render={(props) => (
                  <C {...props} {...rest} />
                )}
              />)
            } else {
              return <PrivateRoute
                key={path}
                path={path}
                exact={exact}
                component={C}
                authentication={authentication}
              />
            }

          })
        }
      </Switch>
    );
  }
}

const mapStateToProps = store => {
  return {
    admin: store.user
  }
}

export default connect(mapStateToProps, {

})(App);
