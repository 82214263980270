import React, { useState, useEffect } from 'react'
import {
  Fade, ListGroup, ListGroupItem
} from 'reactstrap';
import bellIcon from '../../../assets/images/bell.svg';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getNotificationList } from '../../../api/admin';
import { Numbers } from '../../../constants/constant'
import moment from 'moment';

const Notification = (props) => {
  const [fadeIn, setFadeIn] = useState(false);
  let [page, setPage] = useState(Numbers.one);
  let [notifications, setNotifications] = useState([]);
  let notificationRow = []

  async function fetchDetails() {
    const notification = await props.getNotificationList(page, Numbers.ten);
    setNotifications(prevState => [...prevState, notification]);
  }

  useEffect(() => {
    fetchDetails();
  }, []);

  const createMarkup = (message) => {
    return { __html: message };
  }

  const viewMore = () => {
    setPage(++page);
    fetchDetails();
  }

  const toggleFade = async () => {
    setFadeIn(!fadeIn);
    await props.getNotificationList(page, Numbers.ten, true);
  }

  const checkClass = () => {
    return props && props.userNotification && props.userNotification.notification;
  }

  const getTimeDate = (date) => {
    let tempDate = moment(date).format('MM/DD/YYYY')
    let currentDate = moment().format('MM/DD/YYYY')
    let nextDate = moment(tempDate).add(1, 'd').format('MM/DD/YYYY')
    if (tempDate === currentDate) return moment(date).format("hh:mm a")
    if (currentDate === nextDate) return `1 day ago`
    return tempDate
  }

  return (
    <div className="notification-bar">
      {checkClass() && <span className={props.userNotification.notification.un_read ? 'notification-btn new' : 'notification-btn'} href="/" onClick={toggleFade}>
        <img src={bellIcon} alt="notification" width="24" height="24" />
      </span>}
      {props && props.userNotification && props.userNotification.notification && <Fade in={fadeIn} tag="div" className="mt-3">
        <ListGroup>
          {notifications.forEach(notification => {
            notification.rows.map(res => notificationRow.push(res))
          })}
          {notificationRow.length ? notificationRow.map((notification, key) =>
            <ListGroupItem className={notification.read ? '' : 'new'} key={key}>
              <div className="inner">
                <div dangerouslySetInnerHTML={createMarkup(notification.message)}></div>
                <div className='time-date' dangerouslySetInnerHTML={createMarkup(getTimeDate(notification.createdAt))}></div>
              </div>
            </ListGroupItem>)
            : ''}
        </ListGroup>
        {props.userNotification.notification.totalPage > page && <div className="view-more-wrap" onClick={viewMore}>
          <span className="view-btn">View More </span>
        </div>}
      </Fade>}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    userNotification: state.notification
  }
}

export default connect(mapStateToProps, {
  getNotificationList
}
)(withRouter(Notification));
