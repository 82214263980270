import React, { useState, useEffect } from 'react';
import './Login.scss'
import siteLogo from '../../../assets/images/logo.svg';

const LoginStructure = ({ title, children, subtext, tempClass = '' }) => {
  const [winheight, setHeight] = useState();
  useEffect(() => {
    if (window.outerWidth > 767) {
      setHeight(window.innerHeight);
    }
  }, [winheight]);

  return (
    <div className="login-wrapper">
      <div className={`inner-wrapper ${tempClass}`}>
        <div className="form-banner" style={{ height: winheight }}>
          <figure className="site-logo">
            <img src={siteLogo} alt="site logo" width="75" height="75" />
          </figure>
        </div>
        <div className="form-wrap">
          {title ? <h2 className="form-title">{title}</h2> : null}
          {subtext ? <p className="sub-text">{subtext}</p> : null}
          {children}
        </div>
      </div>
    </div>
  )
}
export default LoginStructure;
