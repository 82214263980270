import React from 'react';
import {
  Card, CardTitle, CardText,
} from 'reactstrap';
import { dateFormat } from '../../../utils/method';

const Plan = (props) => {
  return (
    <div className="white-bg-row wide">
      {props && props.userPlans && props.userPlans.data.length ? props.userPlans.data.map((value, index) =>
        <Card body className="wide-card" key={index}>
          {value.name === 'weekly' ?
            <>
              <CardTitle>
                Add Featured Headline
                <div className="purchase-time">
                  <span>Purchased on</span>
                  <span className="data">{dateFormat(value.start_date)}</span>
                </div>
              </CardTitle>
              <strong className="price-range">${value.base_amount}/week</strong>
              <CardText tag="div">
                <ul>
                  <li>
                    Add your music news or projects to our headlines feed
                  </li>
                </ul>
              </CardText>
              <CardTitle className='justify-content-end'>
                <div className="purchase-time end-date">
                  <span>Ending on</span>
                  <span className="data">{dateFormat(value.end_date)}</span>
                </div>
              </CardTitle>
            </>
            :
            <>
              <CardTitle>
                Add Featured Member Status
                <div className="purchase-time">
                  <span>Purchased on</span>
                  <span className="data">{dateFormat(value.start_date)}</span>
                </div>
              </CardTitle>
              <strong className="price-range">${value.base_amount}/month</strong>
              <CardText tag="div">
                <ul>
                  <li>
                    User profile will be listed on top of search result and can add multiple videos, audios & projects
                  </li>
                </ul>
              </CardText>
              <CardTitle className='justify-content-end'>
                <div className="purchase-time end-date">
                  <span>Ending on</span>
                  <span className="data">{dateFormat(value.end_date)}</span>
                </div>
              </CardTitle>
            </>
          }
        </Card>) : <span className="block-label">There is no any plan</span>}
    </div>
  )
}
export default Plan;
