import React from 'react';
import { Link } from 'react-router-dom';

function ErrorPage() {
  return (
    <div className="bg-white p-4 p-md-5 shadow-lg rounded">
      <img src="https://giffyy.s3.ap-south-1.amazonaws.com/assets/error.png" alt="error" className="img-fluid" />
      <h1 className="f-30 mb-4 text-primary mt-5">Ooops, Page not Found!</h1>
      <p>The page you are looking for, can’t be found</p>
      <Link to="/" className="btn btn-primary mt-4">Home</Link>
    </div>
  )
}

export default ErrorPage;
