import axios from "axios";
import "./interceptors";

export function getSongContestList(featured = false) {
  let url = `admin/song_contest?featured=${featured}`;

  return (dispatch) => {
    return axios
      .get(url)
      .then((res) => {
        dispatch({
          type: "GET_SONG_CONTEST_LIST",
          data: res.data,
        });
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function addedFeaturedSongs(songsId) {
  let url = `admin/song_contest/update-featured-songs`;

  return (dispatch) => {
    return axios
      .post(url, {
        featured: true,
        songsId,
      })
      .then((res) => {
        dispatch({
          type: "ADDED_FEATURED_SONGS",
          data: res.data,
        });
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function removedFeaturedSongs(songsId) {
  let url = `admin/song_contest/update-featured-songs`;

  return (dispatch) => {
    return axios
      .post(url, {
        featured: false,
        songsId,
      })
      .then((res) => {
        dispatch({
          type: "REMOVED_FEATURED_SONGS",
          data: res.data,
        });
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function enableSong(id, is_active) {
  let url = `admin/song_contest/enable-songs`;

  return (dispatch) => {
    return axios
      .post(url, {
        id,
        is_active,
      })
      .then((res) => {
        dispatch({
          type: "ENABLED_SONG",
          data: res.data,
        });
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function deleteSongs(songsId) {
  let url = `admin/song_contest/delete-songs`;

  return (dispatch) => {
    return axios
      .post(url, {
        songsId,
      })
      .then((res) => {
        dispatch({
          type: "DELETE_SONGS",
          data: res.data,
        });
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };
}
