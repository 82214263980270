import React from 'react';
import LoginStructure from '../../../components/shared/LoginStructure/LoginStructure';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import SuccessMessage from './SuccessMessage';
import { userPasswordForgot } from '../../../api/admin';
import { connect } from 'react-redux';
import LoadingMask from "react-loadingmask";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      isValid: false,
      onSuccess: false,
      loaded: false,
      userForgotData: true
    };

  }

  handleSubmit = (e) => {
    e.preventDefault();
  }

  handleChange = e => {
    const { value, name } = e.target;
    this.setState({ [name]: value }, (er) => {
      if (this.state.email === "") {
        this.setState({ isValid: false });
      } else {
        this.setState({ isValid: true });
      }
    });
  };

  forgotPassword = async () => {
    this.setState({ loaded: true });
    const userForgotData = await this.props.userPasswordForgot({ email: this.state.email });
    this.setState({ userForgotData: userForgotData, loaded: false });
    if (userForgotData) {
      this.setState({ onSuccess: true, isValid: true });
    }
  }

  errorState = (userForgotData) => {
    if (!userForgotData) {
      return <span className="error">Invalid email address!</span>
    } else {
      return null
    }
  }

  render() {
    let { onSuccess, isValid, loaded, userForgotData, email } = this.state
    return (
      <LoadingMask loading={loaded} text={"loading..."}>
        <LoginStructure>
          {onSuccess && isValid ?
            <SuccessMessage email={email} /> :
            <div>
              <h2 className="form-title">Forgot Password</h2>
              <p className="sub-text">Enter your email and we’ll email you a link to change your password.</p>
              <Form onSubmit={this.handleSubmit}>
                <FormGroup>
                  <Label for="exampleEmail">Email Address</Label>
                  <Input
                    type="email"
                    name="email"
                    id="exampleEmail"
                    value={this.state.email}
                    onChange={this.handleChange}
                    placeholder="Enter registered email address" />
                  {this.errorState(userForgotData)}
                </FormGroup>
                <FormGroup>
                  <Button
                    color="primary"
                    className="wider"
                    onClick={this.forgotPassword}
                    disabled={!this.state.isValid}
                  >
                    Send Link
            </Button>
                </FormGroup>
                <FormGroup className="text-center">
                  <div className="form-group custom-link">
                    Back to <Link className="text-link" to="/login">Sign In</Link>
                  </div>
                </FormGroup>
              </Form>
            </div>
          }
        </LoginStructure>
      </LoadingMask>
    )
  }
}

const mapStateToProps = store => {
  return {
    admin: store.user
  }
}

export default connect(mapStateToProps, {
  userPasswordForgot
})(ForgotPassword);
