import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LoadingMask from "react-loadingmask";
import { ToastContainer, toast, Zoom } from "react-toastify";
import * as Solid from "@heroicons/react/24/solid";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Numbers } from "../../constants/constant";
import DashboardStructure from "../../components/shared/DashboardStructure/DashboardStructure";
import {
  getSchedulerParlourList,
  deleteSchedulerParlour,
  getSchedulerParlourParticipants,
} from "../../api/scheduler-parlour";
import Carousel from "react-elastic-carousel";
import PreloadImage from "react-preload-image";
import iconVideo from "../../assets/images/selected-scheduler-parlour.svg";
import "./schedulerParlour.scss";
import ModalBox from "../../components/shared/pageComponent/ModalBox";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 850, itemsToShow: 3 },
  { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
  { width: 1450, itemsToShow: 5 },
  { width: 1750, itemsToShow: 6 },
  { width: 2050, itemsToShow: 7 },
  { width: 2350, itemsToShow: 8 },
  { width: 2650, itemsToShow: 9 },
  { width: 2950, itemsToShow: 10 },
];

function SchedulerParlour(props) {
  const [loading, setLoading] = useState(false);
  const [parlours, setParlours] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen(prevState => !prevState);

  const getParlourSubmissions = useCallback(async () => {
    try {
      setLoading(true);
      const parlours = await props.getSchedulerParlourList();
      setParlours(parlours);
    } catch (error) {
      toast("Failed to get songs contest", { type: "error" });
    } finally {
      setLoading(false);
    }
  }, [props]);

  useEffect(() => {
    getParlourSubmissions();
  }, [props, getParlourSubmissions]);

  const handleDeleteParlour = async (parlourId) => {
    confirmAlert({
      title: "Confirm to delete selected parlour",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              setLoading(true);
              const response = await props.deleteSchedulerParlour(parlourId);
              await getParlourSubmissions(true);
              toast(response.msg, { type: "success" });
            } catch (error) {
              toast("Failed to delete songs", { type: "error" });
            } finally {
              setLoading(false);
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const getParlourParticipants = useCallback(async (parlourId) => {
    try {
      setLoading(true);
      const { data } = await props.getSchedulerParlourParticipants(parlourId);
      setParticipants(data);
      toggleModal();
    } catch (error) {
      toast("Failed to get parlour participants", { type: "error" });
    } finally {
      setLoading(false);
    }
  }, [props]);

  return (
    <LoadingMask loading={loading} text={"loading..."}>
      <ToastContainer
        position="top-center"
        autoClose={Numbers.twoThou}
        transition={Zoom}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <DashboardStructure title="Scheduler Parlour" history={props.history}>
        <div className="main-scroll">
          <div className="header-parlour">
            <h3 className="title">Parlours Submissions</h3>
          </div>
          <div className="parlours">
            {parlours.length === 0 && (
              <div className="empty">Not songs submissions.</div>
            )}
            {!loading && parlours.length > 0 && (
              <Carousel
                breakPoints={breakPoints}
                pagination={false}
                renderArrow={({ type, onClick, isEdge }) => {
                  const pointer =
                    type === "PREV" ? (
                      <Solid.ChevronLeftIcon />
                    ) : (
                      <Solid.ChevronRightIcon />
                    );
                  return (
                    <button
                      className="arrow-slider"
                      onClick={onClick}
                      disabled={isEdge}
                    >
                      {pointer}
                    </button>
                  );
                }}
              >
                {parlours.map((parlour, index) => {
                  return (
                    <div key={index} className="card-parlour">
                      <div className="card-poster">
                        {parlour.live && (
                          <div className="card-live">
                            <div className="card-live-circle" />
                            <span className="card-live-text">Live</span>
                          </div>
                        )}
                        <PreloadImage
                          className="card-img"
                          src={parlour.poster}
                          alt={parlour.suite_title}
                          lazy
                        />
                        <div className="bg-black" />
                        <div className="card-info">
                          <h3 className="card-title">{parlour.suite_title}</h3>
                          <h1 className="card-description">
                            '{parlour.description}'
                          </h1>
                          <span className="card-date">
                            {moment
                              .utc(parlour.start_date)
                              .local()
                              .format("MMM DD, h:mma")}{" "}
                            EST
                          </span>
                        </div>
                        <div className="parlour-video">
                          <PreloadImage
                            className="parlour-icon_video"
                            src={iconVideo}
                            alt="video_icon"
                            lazy
                          />
                        </div>
                      </div>
                      <div className="card-participants">
                        <div className="card-participant">
                          <h3 className="card-participant_title">Host:</h3>
                          <div className="card-participant_detail">
                            <PreloadImage
                              className="card-participant_photo"
                              src={parlour.owner.profile_image}
                              alt={parlour.owner.name}
                              lazy
                            />
                            <span className="card-participant_name">
                              {parlour.owner.name}
                            </span>
                          </div>
                        </div>
                        {parlour.guest && (
                          <div className="card-participant">
                            <h3 className="card-participant_title">Guest:</h3>
                            <div className="card-participant_detail">
                              <PreloadImage
                                className="card-participant_photo guest"
                                src={parlour.guest.profile_image}
                                alt={parlour.guest.name}
                                lazy
                              />
                              <span className="card-participant_name">
                                {parlour.guest.name}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="card-actions">
                        <button
                          disabled={loading}
                          className="delete-parlour"
                          onClick={() => getParlourParticipants(parlour.id)}
                        >
                          All RSVP
                        </button>
                        <button
                          disabled={loading}
                          className="delete-parlour"
                          onClick={() => handleDeleteParlour(parlour.id)}
                        >
                          Delete Parlour
                        </button>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            )}
          </div>
        </div>
      </DashboardStructure>
      <ModalBox
        isOpen={isModalOpen}
        ModalTitle="Participants"
        withHeader
        toggle={toggleModal}
        className="modal-right">
        <div>
          {participants.map((p, index) => (
            <div key={index} className="participant">
              <img className="participant-image" height="43" width="43" alt={p.name} src={p.profile_image} />
              <span className="participant-detail">
                <span className="name">{p.name}</span>
                <span className="email">{p.email}</span>
              </span>
            </div>
          ))}
        </div>
      </ModalBox>
    </LoadingMask>
  );
}

const mapStateToProps = (store) => {
  return {
    admin: store.user,
  };
};

export default connect(mapStateToProps, {
  getSchedulerParlourList,
  deleteSchedulerParlour,
  getSchedulerParlourParticipants,
})(withRouter(SchedulerParlour));
