import React from 'react'
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import Select from 'react-select';

const FilterForm = (props) => {

  const checkDisabled = (prop) => {
    if (prop.isDisabled) {
      return prop.isDisabled;
    } else if ((!prop.city || prop.city === "")
      && (!prop.state || prop.state === "") && (!prop.zipcode || prop.zipcode === "")
      && prop.selectedTalent && !prop.selectedTalent.length &&
      !prop.selectedProfileType) {
      return true;
    }
    return false;
  }

  return (
    <Form>
      {props && <FormGroup>
        <Label for="selectTalent">Select Talent</Label>
        <Select
          value={props.selectedTalents ? props.selectedTalents : props.selectedTalent}
          onChange={props.selectTalents}
          options={props.talentLists}
          isMulti
          className="form-control select"
        />
      </FormGroup>}
      <FormGroup className="manualCl">
        <Label for="profileType">Select Profile Type</Label>
        <Input type="select" name="profileType" onChange={props.selectProfile} id="profileType">
          <option value="">Select</option>
          <option value="Featured" selected={props.selectedProfileType === 'Featured' ? true : false}>Featured</option>
          <option value="Non-Featured" selected={props.selectedProfileType === 'Non-Featured' ? true : false}>Non-Featured</option>
        </Input>
      </FormGroup>
      <strong className="subhead">Location</strong>
      <FormGroup>
        <Label for="city">City</Label>
        <Input type="text" onChange={props.handleChange} value={props.city ? props.city : ''} name="city" id="city" placeholder="Enter city" />
      </FormGroup>
      <FormGroup>
        <Label for="state">State</Label>
        <Input type="text" onChange={props.handleChange} value={props.state ? props.state : ''} name="state" id="state" placeholder="Enter state" />
      </FormGroup>
      <FormGroup>
        <Label for="zip">Zip Code</Label>
        <Input type="text" onChange={props.handleChange} value={props.zipcode ? props.zipcode : ''} name="zipcode" id="zipcode" placeholder="Enter zipcode" />
      </FormGroup>
      <FormGroup className="btn-wrap">
        <Button color="primary" onClick={props.applyFilter}
          disabled={checkDisabled(props)}>Apply</Button>
        <Button type="reset" outline onClick={props.clearFilter}>Clear</Button>
      </FormGroup>
    </Form>
  )
}
export default FilterForm;
