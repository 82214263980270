import React from 'react';
import SideBar from '../../shared/pageComponent/SideBar';
import HeaderFile from '../../shared/pageComponent/HeaderFile';
import './DashboardStructure.scss';

const DashboardStructure = ({ title, children, history }) => {

  return (
    <div className="main-container">
      <SideBar />
      <div className="content-box">
        <HeaderFile title={title} history={history} />
        {children}
      </div>

    </div>
  )
}
export default DashboardStructure;
