import React, { useState } from 'react'
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { changeAdminPasswordApi } from '../../api/admin';
import { withRouter } from 'react-router-dom';
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Numbers } from '../../constants/constant'

const ChangePassword = (props) => {

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);

  const validatePassword = (newpassword = '') => {
    setIsPasswordValid(false);
    if (newpassword.length > 0) {
      var p = /^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z!@#$%^&*]{6,20}$/;
      if (newpassword.match(p)) {
        setIsPasswordValid(true);
      }
    }
    return isPasswordValid;
  }

  const handleChange = e => {
    const { value, name } = e.target;
    if (name === 'oldpassword') {
      setCurrentPassword(value);
    } else if (name === 'newpassword') {
      validatePassword(value);
      setNewPassword(value);
    } else {
      setPassword(value);
    }
  };

  const changeAdminPassword = async () => {
    if (newPassword === password) {
      setIsPasswordMatch(true);
    } else {
      setIsPasswordMatch(false);
      return;
    }
    const data = {
      current_password: currentPassword,
      password: password,
      user_id: parseInt(localStorage.getItem('userId'))
    }
    const changePssword = await props.changeAdminPasswordApi(data);
    if (changePssword && changePssword.data && changePssword.status === Numbers.twoHun) {
      toast(changePssword.data && changePssword.data.msg);
      localStorage.removeItem('token');
      props.history.push('/');
    } else {
      toast(changePssword && changePssword.data && changePssword.data.msg, { type: 'error' });
    }
  }

  return (
    <Form>
      <ToastContainer
        position="top-center"
        autoClose={Numbers.twoThou}
        transition={Zoom}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <FormGroup>
        <Label for="examplePassword">Current Password</Label>
        <Input type="password" name="oldpassword"
          onChange={handleChange}
          id="oldpassword" placeholder="Enter current password" />
      </FormGroup>
      <FormGroup>
        <Label for="examplePassword">New Password</Label>
        <Input type="password" spellcheck="false" autocomplete="off"
          name="newpassword" id="newpassword"
          onChange={handleChange}
          placeholder="Enter new password" />
        {!isPasswordValid && <span className="error">Password must be alphanumeric with min length six</span>}
      </FormGroup>
      <FormGroup>
        <Label for="examplePassword">Confirm Password</Label>
        <Input type="password"
          onChange={handleChange}
          name="conpassword" id="conpassword"
          placeholder="Enter confirm password" />
        {!isPasswordMatch && <span className="error">Confirm password doesn't match.</span>}
      </FormGroup>
      <FormGroup className="btn-wrap">
        <Button color="primary" disabled={!currentPassword || !newPassword || !password} onClick={changeAdminPassword}>Change</Button>
      </FormGroup>
    </Form>
  )
}

const mapStateToProps = store => {
  return {
    admin: store.user
  }
}

export default connect(mapStateToProps, {
  changeAdminPasswordApi
})(withRouter(ChangePassword));
