const initData = {
  notification: null
}

export default function (state = initData, action = {}) {
  if (action.type === "NOTIFICATION_LIST_ACTION") {
    const notification = action.data;
    return {
      ...state,
      notification
    };
  } else {
    return {
      ...state,
    }
  }
}
