import React from 'react';
import successIcon from '../../../assets/images/tick.svg';
import errorIcon from '../../../assets/images/error.svg';

const EmailVerifyMessage = (props) => {
  return (
    <div className="success-message text-center">
      <figure className="success-icon">
        {props.isSuccess ? <img src={successIcon} width="70" height="70" alt="success icon" />
          : <img src={errorIcon} width="70" height="70" alt="success icon" />}
      </figure>
      <h3>Email verification</h3>
      {true ? <p className="sub-text">
        <p> Your account has been verified.</p>
        You can now go back to the app and login with provided details to access more features.
      </p> : <p className="sub-text">Your token has been expired!
      </p>}
    </div>
  )
}
export default EmailVerifyMessage;
