import axios from 'axios';
import "./interceptors";

export function getContentData() {
  return dispatch => {
    return axios.get(`api/v1/page/term-privacy`).then(res => {
      return res.data
    }).catch(err => { return false })
  }
}

export default {
  getContentData
}
