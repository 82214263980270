import React, { Fragment, useState, useEffect } from 'react';
import {
  Row, Col, Media
} from 'reactstrap';
import { Link } from 'react-router-dom';
import ReactStars from "react-rating-stars-component";
import DummyIcon from '../../../assets/images/user.jpg';
import { Numbers } from '../../../constants/constant';
const ratingStar = {
  size: Numbers.tw24,
  value: Numbers.zero,
  edit: false
};

let youtubeVideoLinks = [];
let vimeoVideoLinks = [];

const proxyurl = "https://vimeo.com/api/oembed.json?url=";
const not_found = 'https://i.vimeocdn.com/video/not_found.jpg';

const Profile = (props) => {
  const userData = props.user;
  ratingStar.value = userData && userData.rating && parseFloat(userData.rating);

  const [videoContainer, setVideoContainer] = useState('');

  const openVideo = (videoLinks) => {
    window.open(videoLinks, '_blank');
  }


  const getThumbnail = (url) => {
    if (typeof (url) !== 'object') {
      if (url.includes('youtube') || url.includes('youtu.be')) {
        let data = '';
        if (url.includes('youtu.be')) {
          data = url.split('youtu.be/');
        } else {
          data = url.split('watch?v=');
        }
        return `http://i1.ytimg.com/vi/${data[1]}/0.jpg`;
      } else {
        let data = url.split('vimeo.com/');
        return `https://i.vimeocdn.com/video/${data[1]}.jpg`;
      }
    } else {
      return not_found;
    }
  }

  const allVideoData = async (videoData) => {
    if (videoData && videoData.length) {
      youtubeVideoLinks = [];
      vimeoVideoLinks = [];
      videoData.forEach(async (element, key) => {
        let videoId;
        if (element.includes('youtube') || element.includes('youtu.be')) {
          if (element.includes('youtu.be')) {
            videoId = element.split('youtu.be/');
          } else {
            videoId = element.split('watch?v=');
          }
          const urls = `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId[1]}&key=AIzaSyCaKAZitW_HiihWHc6icXPS779dPnjywR4`;
          let til = await fetch(urls)
            .then(response => response.json());
          youtubeVideoLinks.push({ url: urls, src: element, titles: til });
          setVideoContainer(
            <Media className="square-box" key={key}>
              <Media left className="vedio" onClick={() => openVideo(element)}>
                <Media width="81" height="81" object src={getThumbnail(element)} alt="Generic placeholder image" />
                <video key={key} width="320" height="240">
                  <source src={element} type="video/mp4" />
                  Your browser does not support the video tag.
           </video>
              </Media>
              <Media body>
                <Media heading>{til && til.items && til.items.length && til.items[0].snippet && til.items[0].snippet.title}</Media>
              </Media>
            </Media>
          );
        } else if (element.includes('vimeo.com')) {
          videoId = element.split('vimeo.com/');
          const urls = `https://player.vimeo.com/video/${videoId[1]}/config`;
          let til = await fetch(proxyurl + urls)
            .then(response => response.json());
          vimeoVideoLinks.push({ url: urls, src: element, titles: til });
          setVideoContainer(
            <Media className="square-box" key={key}>
              <Media left className="vedio" onClick={() => openVideo(element)}>
                <Media width="81" height="81" object src={getThumbnail(element)} alt="Generic placeholder image" />
                <video key={key} width="320" height="240">
                  <source src={element} type="video/mp4" />
                  Your browser does not support the video tag.
           </video>
              </Media>
              <Media body>
                <Media heading>{til && til.video && til.video.title}</Media>
              </Media>
            </Media>
          );
        } else {
          return 'N/A'
        }
      })
    }
  }

  useEffect(() => {
    youtubeVideoLinks = [];
    vimeoVideoLinks = [];
    const timer = setTimeout(() => {
      allVideoData(props && props.user && props.user.video_links);
    }, 2000);
    return () => clearTimeout(timer);
  }, [props]);

  return (
    <div>
      <Row>
        <Col sm="12">
          <Link to={`/edit-profile/${userData && userData.id}`} className="btn edit-profile btn-primary">Edit Profile</Link>
          <div className="profile-info">
            {userData && <Media className="profile">
              <Media left href="#">
                <Media width="80" height="80" object src={userData && userData.profile_image ? userData.profile_image : DummyIcon} alt="Generic placeholder image" />
              </Media>
              <Media body>
                <Media heading>
                  {userData && userData.name}
                  <span className="highlight">
                    {userData && userData.featured ? 'Featured' : 'Non-Featured'}
                  </span>
                </Media>
                <div className="talent-wrap">
                  {userData && userData.roles && userData.roles.length ? userData.roles.map((value, index) => {
                    return <Fragment key={index}>
                      <span className="talent">{value && value.title}</span>
                      {userData.roles.length === index + 1 ? '' : <span className="talent sep">,</span>}
                    </Fragment>
                  }
                  ) : 'N/A'}
                </div>
                <div className="speciality">
                  {userData && userData.genres && userData.genres.length ? userData.genres.map((value, index) =>
                    <span className="tags" key={index}>{value.title}</span>
                  ) : ''}
                </div>
              </Media>
            </Media>}
            {ratingStar && ratingStar.value ? <div className="rating-wrap">
              <ReactStars {...ratingStar} />
              <strong className="rating">{ratingStar.value}</strong>
            </div> : ''}
          </div>
        </Col>
      </Row>
      <Row className="data-box">
        <Col xs="12">
          <strong className="label">Bio</strong>
          <span className="data">{userData && userData.bio ? userData.bio : 'N/A'}</span>
        </Col>
      </Row>
      <Row className="data-row">
        <Col xs="4" className="data-box">
          <strong className="label">Email</strong>
          <span className="data">{userData && userData.email}</span>
        </Col>
        <Col xs="4" className="data-box">
          <strong className="label">City</strong>
          <span className="data">{userData && userData.city ? userData.city : 'N/A'}</span>
        </Col>
        <Col xs="4" className="data-box">
          <strong className="label">State</strong>
          <span className="data">{userData && userData.state ? userData.state : 'N/A'}</span>
        </Col>
      </Row>
      <Row className="data-row">
        <Col xs="4" className="data-box">
          <strong className="label">Zip Code</strong>
          <span className="data">{userData && userData.zipcode ? userData.zipcode : 'N/A'}</span>
        </Col>
        <Col xs="4" className="data-box">
          <strong className="label">Website Link</strong>
          <span className="data">{userData && userData.website_url ? userData.website_url : 'N/A'}</span>
        </Col>
        <Col xs="4" className="data-box">

        </Col>
      </Row>
      <Row className="video-row">
        <Col xs="12">
          <strong className="title">Youtube & Vimeo URL</strong>
          {(youtubeVideoLinks.length || vimeoVideoLinks.length) === 0 && (<span className="data">N/A</span>)}
          <div className="inner-row">
            {youtubeVideoLinks && youtubeVideoLinks.length > 0 ? youtubeVideoLinks.map((value, index) =>
              <Media className="square-box with-space" key={index}>
                <Media left className="vedio" onClick={() => openVideo(value.src)}>
                  <Media width="81" height="81" object src={getThumbnail(value.src)} alt="Generic placeholder image" />
                  <video key={index} width="320" height="240">
                    <source src={value.src} type="video/mp4" />
                  Your browser does not support the video tag.
                  </video>
                </Media>
                <Media body>
                  <Media heading>{value.titles && value, value.titles.items && value.titles.items.length && value.titles.items[0].snippet && value.titles.items[0].snippet.title}</Media>
                </Media>
              </Media>) : ''}
            {vimeoVideoLinks && vimeoVideoLinks.length > 0 ? vimeoVideoLinks.map((value, index) =>
              <Media className="square-box" key={index}>
                <Media left className="vedio" onClick={() => openVideo(value.src)}>
                  <Media width="81" height="81" object src={getThumbnail(value.src)} alt="Generic placeholder image" />
                  <video key={index} width="320" height="240">
                    <source src={value.src} type="video/mp4" />
                  Your browser does not support the video tag.
                  </video>
                </Media>
                <Media body>
                  <Media heading>{value.titles && value.titles.video && value.titles.video.title}</Media>
                </Media>
              </Media>) : ''}
          </div>
        </Col>
      </Row>
    </div>
  )
}
export default Profile;
