import React from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ButtonGroup,
  FormText,
} from "reactstrap";

const HeadlineForm = (props) => {
  const {
    loaded,
    selected,
    title,
    video_url,
    description,
    website_link,
    news_photo,
    activeType,
    handleChange,
  } = props;

  const checkDisablity = () => {
    if (selected === 1) {
      return title === "" || description === "" || website_link === ""
        ? true
        : false;
    }
    if (selected === 2) {
      return title === "" || website_link === "" || video_url === null
        ? true
        : false;
    }
    if (selected === 3) {
      return title === "" || website_link === "" || news_photo.length === 0
        ? true
        : false;
    }
  };

  return (
    <Form className="create-head">
      <FormGroup>
        <Label for="newstype">News Type</Label>
        <ButtonGroup>
          <Button
            color="primary"
            outline
            onClick={() => activeType(1, "text")}
            active={selected === 1}
          >
            Text
          </Button>
          <Button
            color="primary"
            outline
            onClick={() => activeType(2, "video")}
            active={selected === 2}
          >
            Video
          </Button>
          <Button
            color="primary"
            outline
            onClick={() => activeType(3, "image")}
            active={selected === 3}
          >
            Image
          </Button>
        </ButtonGroup>
      </FormGroup>
      <div className="head-divider"></div>
      <div>
        <h3 className="head-title">New Details</h3>
        <FormGroup>
          <Label for="newstitle">News Title</Label>
          <Input
            type="text"
            name="title"
            value={title}
            id="newstitle"
            placeholder="Enter News Title"
            onChange={handleChange}
          />
        </FormGroup>

        {selected === 1 && (
          <>
            <FormGroup>
              <Label for="newsdescription">News Description*</Label>
              <Input
                type="textarea"
                name="description"
                value={description}
                id="newsdescription"
                placeholder="Enter News Descripcion"
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="newslink">News link</Label>
              <Input
                type="text"
                name="website_link"
                value={website_link}
                id="newslink"
                placeholder="Enter Link"
                onChange={handleChange}
              />
            </FormGroup>
          </>
        )}

        {selected === 2 && (
          <>
            <FormGroup>
              <Label for="addlink">Add link</Label>
              <Input
                type="text"
                name="website_link"
                value={website_link}
                id="addlink"
                placeholder="Enter Link"
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup className="file-field">
              <Label>Video</Label>
              <div className={`add-file-btn ${video_url && "full"}`}>
                {video_url && <div className="text-file">{video_url.name}</div>}
                {!video_url && <div className="btn-plus">+</div>}
                <Input
                  type="file"
                  name="video_url"
                  onChange={(e) => props.onVideoChange(e)}
                  accept="video/*"
                />
              </div>
              <FormText>Upload up to 60 seconds</FormText>
            </FormGroup>
          </>
        )}

        {selected === 3 && (
          <>
            <FormGroup>
              <Label for="addlink">Add link</Label>
              <Input
                type="text"
                name="website_link"
                value={website_link}
                id="addlink"
                placeholder="Enter Link"
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup className="file-field">
              <Label>Photos (upload up to 5 photos)</Label>
              <div className="add-file-btn">
                <div className="btn-plus">+</div>
                <Input
                  type="file"
                  name="news_photo"
                  onChange={(e) => props.onPhotosChange(e)}
                  accept="image/*"
                />
              </div>
            </FormGroup>
            <FormGroup className="selected-photo-files">
              {news_photo.length > 0 &&
                news_photo.map((value, key) => {
                  return (
                    <div key={key} className="photo-file-list">
                      <strong className="file-title">{value.name}</strong>
                      <span
                        className="remove-btn"
                        onClick={() => props.removePhoto(key)}
                      >
                        remove
                      </span>
                    </div>
                  );
                })}
            </FormGroup>
          </>
        )}
      </div>
      <FormGroup className="btn-wrap">
        <Button
          color="primary"
          onClick={props.createHeadline}
          disabled={loaded || checkDisablity()}
        >
          {loaded ? "creating..." : "Create"}
        </Button>
      </FormGroup>
    </Form>
  );
};

export default HeadlineForm;
