import React from 'react';
import LoginStructure from '../../../components/shared/LoginStructure/LoginStructure';
import EmailVerifyMessage from './EmailVerifyMessage';
import { connect } from 'react-redux';
import LoadingMask from "react-loadingmask";
import { withRouter } from 'react-router-dom';
import { emailVerification } from '../../../api/admin';
import { Numbers } from '../../../constants/constant'

class ShowMessageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      isValid: false,
      onSuccess: false,
      loaded: false,
    };
  }

  async componentDidMount() {
    this.setState({ loaded: true });
    if (this.props && this.props.location) {
      const tok = (this.props.location.search).split('=');
      this.setState({ token: tok[Numbers.one] });
      localStorage.setItem('token', tok[Numbers.one]);
    }
    const verify = await this.props.emailVerification();
    if (verify) {
      this.setState({ onSuccess: true });
    } else {
      this.setState({ onSuccess: false });
    }
    this.setState({ loaded: false });
  }

  render() {
    const { loaded, onSuccess } = this.state;
    return (
      <LoadingMask loading={loaded} text={"loading..."}>
        <LoginStructure tempClass='remove-image'>
          {!loaded && <EmailVerifyMessage isSuccess={onSuccess} loaded={loaded} />}
        </LoginStructure>
      </LoadingMask>
    )
  }
}

const mapStateToProps = store => {
  return {
    admin: store.user
  }
}

export default connect(mapStateToProps, {
  emailVerification
})(withRouter(ShowMessageContainer));
