import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';

const searchOptions = {
  types: ['(cities)']
}

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: '' };
  }

  componentDidMount() {
    if (this.props.ApiAddress) {
      this.setState({ address: this.props.ApiAddress })
    }
  }

  handleChange = address => {
    this.setState({ address });
    this.props.isAddress(address);
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then(async (results) => {
        const getLatLngs = await getLatLng(results[0]);
        if (results[0]) {
          const locationData = (results[0].formatted_address).split(',');
          this.props.selectedLocation(results[0] && results[0].formatted_address, getLatLngs)
          this.setState({ address: `${locationData[0]},${locationData[1]}` });
        }
      })
      .catch(error => console.error('Error', error));
  };

  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: 'location-search-input',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}
export default LocationSearchInput;
