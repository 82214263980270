import React from 'react';
import LoginStructure from '../../../components/shared/LoginStructure/LoginStructure';

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <LoginStructure title="Signup">
        <h1>Signup</h1>
      </LoginStructure>
    )
  }
}

export default Signup;
