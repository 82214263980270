const initData = {
  user: null
}

export default function users(state = initData, action = {}) {
  if (action.type === "ACTION_GET_PROFILE") {
    return {
      user: {},
    };
  } else {
    return {
      ...state,
    }
  }
}
