import React from 'react';
import { Button } from 'reactstrap';

const NotificationMessage = (props) => {
  const { closeModal, openNot } = props;
  return (
    <div className="note-body">
      <p className="msg">Are you sure you want to {props.userAction} this {props.item}?</p>
      <div className="action-wrap">
        <Button onClick={props.closeModal} color="secondry">Cancel</Button>
        <Button onClick={() => {
          closeModal();
          openNot(props);
        }} color="primary">Yes</Button>
      </div>
    </div>
  )
}

export default NotificationMessage;
