import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Header from './Header.jsx'
import './Content.scss'
import LoadingMask from "react-loadingmask";

import { getContentData } from '../../api/page';

class TermsOfUse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  async componentDidMount() {
    this.setState({ loaded: true })
    const contentData = await this.props.getContentData();
    this.setState({
      terms: contentData.term,
      loaded: false
    })
  }

  render() {
    return (
      <LoadingMask loading={this.state.loaded} text={"loading..."}>
        <Header />
        <div className='content' dangerouslySetInnerHTML={{ __html: this.state.terms }}>
        </div>
      </LoadingMask>
    )
  }
}

const mapStateToProps = store => {
  return {}
}

export default connect(mapStateToProps, {
  getContentData
})(withRouter(TermsOfUse));

