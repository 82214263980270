import React from 'react';
import { Button, Form, FormGroup, Label, Input, CustomInput } from 'reactstrap';
import Autocomplete from 'react-autocomplete';

const PodcastForm = (props) => {
  const { name, s3_audio_key, userId } = props;

  const checkDisablity = (name, s3_audio_key, userId) => {
    return name === '' || (s3_audio_key && s3_audio_key.length === 0) || !userId ? true : false;
  }

  return (
    <Form className="create-pod">
      {props && <FormGroup>
        <Label for="podcastname">Podcast Name</Label>
        <Input type="text" name="name" value={props.name} id="podcastname" placeholder="Enter podcast name" onChange={props.handleChange} />
      </FormGroup>}
      {props && <FormGroup className="user-search-control">
        <Label for="username">Select User</Label>
        <Autocomplete
          getItemValue={(item) => item.value}
          items={props.userLists ? props.userLists : []}
          renderItem={(item, isHighlighted) => item.label}
          value={props.inputValue}
          onChange={props.searchInputChange}
          onSelect={props.handleSelect}
        />
      </FormGroup>}
      {props && <FormGroup className="customswitch">
        <CustomInput
          type="switch"
          id="exampleCustomSwitch"
          name="is_show"
          ref={props.InputSwitch}
          value={props.is_show}
          checked={props.is_show}
          onChange={(e) => props.handleCustom(e)}
          label="Would you like to show this podcast on app?"
        />
      </FormGroup>}
      <FormGroup className="file-field">
        <div className="add-file-btn">
          <Input type="file" name="file"
            onChange={(e) => props.onFileChange(e)}
            onClick={(e) => e.target.value = null}
            accept="audio/*" id="exampleFile" />
        </div>
      </FormGroup>
      <FormGroup className="selected-audio-files">
        {props.s3_audio_key && props.s3_audio_key.length ? props.s3_audio_key.map((value, key) => {
          return (
            <div className="audio-file-list" key={key}>
              <strong className="file-title">
                {value.split('audio/')[1]}
              </strong>
              <span className="remove-btn" onClick={() => props.removeTrack(value)}>
                remove
              </span>
            </div>
          )
        }) : ''}
      </FormGroup>
      <FormGroup className="btn-wrap">
        {!props.title && <Button color="primary" onClick={props.createPodcast} disabled={checkDisablity(name, s3_audio_key, userId)} >Create</Button>}
        {props.title && <Button color="primary" onClick={props.updatePodcast} disabled={checkDisablity(name, s3_audio_key, userId)} >Update</Button>}
      </FormGroup>
    </Form>
  )
}

export default PodcastForm;
