import React, { useState, useEffect } from 'react';
import { FormGroup } from 'reactstrap';
import { Link } from 'react-router-dom';
import successIcon from '../../../assets/images/tick.svg';
import LoginStructure from '../../../components/shared/LoginStructure/LoginStructure';

const ResetPasswordMessage = (props) => {
  const [isAdmin, setIsAdmin] = useState('');
  const tempString = (props.location.search).split('=')[1];
  useEffect(() => {
    setIsAdmin(tempString === 'true' ? true : false);
  }, []);
  return (
    <LoginStructure tempClass='remove-image'>
      <div className="success-message text-center">
        <figure className="success-icon">
          <img src={successIcon} width="70" height="70" alt="success icon" />
        </figure>
        <h3>Congratulations</h3>
        <p className="sub-text">Your password has been successfully changed.</p>
        {isAdmin &&
          <FormGroup>
            <Link
              className="btn btn-primary"
              to="/login">
              Back to Sign In
          </Link>
          </FormGroup>
        }
      </div>
    </LoginStructure>
  )
}
export default ResetPasswordMessage;
