import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const ModalBox = (props) => {
  const {
    children,
    ModalTitle,
    className
  } = props;

  return (
    <div>
      <Modal isOpen={props.isOpen} toggle={props.toggle} className={className}>
        {props.withHeader ? <ModalHeader toggle={props.toggle}>{ModalTitle}</ModalHeader> : "" }
        <ModalBody>
          {children}
        </ModalBody>
      </Modal>
    </div>
  );
}
export default ModalBox;
