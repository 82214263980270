import React, { useState, useEffect } from 'react';
import {
  Card, CardTitle, Media,
  Breadcrumb, BreadcrumbItem
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { projectDetail } from '../../../api/admin';
import LoadingMask from "react-loadingmask";
import DashboardStructure from '../../../components/shared/DashboardStructure/DashboardStructure';
import { dateFormat, timeFormat } from '../../../utils/method';

const ProjectDetails = (props) => {

  const [projectData, setProjectData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [videoContainer, setVideoContainer] = useState([]);
  const [id, setUserId] = useState();

  const openVideo = (videoLinks) => {
    window.open(videoLinks, '_blank');
  }

  const proxyurl = "https://vimeo.com/api/oembed.json?url=";
  const not_found = 'https://i.vimeocdn.com/video/not_found.jpg';

  const getThumbnail = (url) => {
    if (url) {
      if (url.includes('youtube') || url.includes('youtu.be')) {
        let data = '';
        if (url.includes('youtu.be')) {
          data = url.split('youtu.be/');
        } else {
          data = url.split('watch?v=');
        }
        return `http://i1.ytimg.com/vi/${data[1]}/0.jpg`;
      } else {
        let data = url.split('vimeo.com/');
        return `https://i.vimeocdn.com/video/${data[1]}.jpg`;
      }
    }
    return not_found;
  }

  const allVideoData = async (videoData) => {
    if (videoData && videoData.length) {
      let d = [];
      videoData.forEach(async (element, key) => {
        let videoId;
        if (element.includes('youtube') || element.includes('youtu.be')) {
          if (element.includes('youtu.be')) {
            videoId = element.split('youtu.be/');
          } else {
            videoId = element.split('watch?v=');
          }
          const urls = `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId[1]}&key=AIzaSyCaKAZitW_HiihWHc6icXPS779dPnjywR4`;
          let til = await fetch(urls)
            .then(response => response.json());
          d.push(<Media className="square-box" key={key}>
            <Media left className="vedio" onClick={() => openVideo(element)}>
              <Media width="81" height="81" object src={getThumbnail(element)} alt="Generic placeholder image" />
              <video key={key} width="320" height="240">
                <source src={element} type="video/mp4" />
                Your browser does not support the video tag.
       </video>
            </Media>
            <Media body>
              <Media heading>{til && til.items && til.items.length && til.items[0].snippet && til.items[0].snippet.title}</Media>
            </Media>
          </Media>)
          if (videoData.length === key + 1) {
            setVideoContainer(d)
          }
        } else if (element.includes('vimeo.com')) {
          videoId = element.split('vimeo.com/');
          const urls = `https://player.vimeo.com/video/${videoId[1]}/config`;
          let til = await fetch(proxyurl + urls)
            .then(response => response.json())
          d.push(<Media className="square-box" key={key}>
            <Media left className="vedio" onClick={() => openVideo(element)}>
              <Media width="81" height="81" object src={getThumbnail(element)} alt="Generic placeholder image" />
              <video key={key} width="320" height="240">
                <source src={element} type="video/mp4" />
                Your browser does not support the video tag.
       </video>
            </Media>
            <Media body>
              <Media heading>{til && til.items && til.items.length && til.items[0].snippet && til.items[0].snippet.title}</Media>
            </Media>
          </Media>)
          if (videoData.length === key + 1) {
            setVideoContainer(d)
          }
        } else {
          return 'N/A'
        }
      })
    }
  }

  async function fetchDetails() {
    setLoaded(true);
    const userId = props.location.pathname.split('/project-detail/');
    let data = await props.projectDetail(userId[1]);
    if (data) {
      setUserId(data.data.user_id);
      setProjectData(data.data);
      allVideoData(data.data && data.data.video_links)
    }
    setLoaded(false);
  }


  useEffect(() => {
    fetchDetails();
  }, [props]);

  return (
    <LoadingMask loading={loaded} text={"loading..."}>
      <DashboardStructure title="User Management" history={props.history}>
        <div className="header-section">
          <div className="title-wrap">
            <h3>Project Details</h3>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/user-list">User Listings</Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Link to={`/user-detail/${id}`}>User Details</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>Project Details</BreadcrumbItem>
            </Breadcrumb>
          </div>
        </div>
        <div className="white-bg-row wide">
          <Card body>
            <CardTitle className="black">
              <strong>
                {projectData.title}
              </strong>
              <span className={`card-status ${projectData.type === 'upcoming' ? 'new' : ''}`}>{projectData.type}</span>
            </CardTitle>
            <span className={`post-time e-space`}>{dateFormat(projectData.createdAt)}  |  {timeFormat(projectData.createdAt)}</span>
            <div className="data-box">
              <strong className="label">Description</strong>
              <span className="data">{projectData.description}</span>
            </div>
            <div className="data-box video-row">
              <strong className="label">
                Pictures
            </strong>
              {projectData.pictures && projectData.pictures.length ? <div className="inner-row">
                {projectData.pictures.map((value, index) => <Media className="square-box" key={index}>
                  <Media left>
                    <img src={value} alt="pictures" />
                  </Media>
                </Media>)}
              </div> : 'N/A'}
            </div>
            <div className="data-box video-row">
              <strong className="label">
                Youtube and Vimeo Videos
            </strong>
              {videoContainer.length ? <div className="inner-row">
                {videoContainer.map((value, key) => value)}
              </div> : 'N/A'}
            </div>
          </Card>
        </div>
      </DashboardStructure>
    </LoadingMask>
  )
}

const mapStateToProps = store => {
  return {
    admin: store.user
  }
}

export default connect(mapStateToProps, {
  projectDetail,
})(withRouter(ProjectDetails));

