import React from 'react';
import DashboardStructure from '../../components/shared/DashboardStructure/DashboardStructure';
import { Button } from 'reactstrap';
import ModalBox from '../../components/shared/pageComponent/ModalBox';
import DataTable from 'react-data-table-component';
import DummyIcon from '../../assets/images/user.jpg';
import { NavLink, withRouter } from 'react-router-dom';
import PodcastForm from './PodcastForm';
import {
  userList, getAwsCredentials, createPodcast,
  getPodcastList, searchUserByName,
} from '../../api/admin';
import { connect } from 'react-redux';
import S3FileUpload from 'react-s3';
import LoadingMask from "react-loadingmask";
import { ToastContainer, toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Numbers } from '../../constants/constant'
import Pagination from "react-js-pagination";

class PodcastListing extends React.Component {

  constructor(props) {
    super(props);
    this.InputSwitch = React.createRef();
    this.state = {
      isModalOpen: false,
      name: '',
      user: '',
      is_show: false,
      audio_url: [],
      loaded: false,
      userLists: [],
      awsCredentials: null,
      fileData: null,
      s3_audio_key: [],
      s3_audio_duration: [],
      userId: null,
      isDisabled: true,
      urls: [],
      podcastsList: [],
      totalCounts: 0,
      limit: 10,
      activePage: 1,
      sort: false,
      sortBy: null,
      inputValue: ''
    }
  }

  getPodCastList = async (pageNumber, sortBy = this.state.sortBy, sortDir = this.state.sort) => {
    const podcastList = await this.props.getPodcastList(pageNumber ? pageNumber : 1, sortBy, sortDir);
    this.setState({ podcastsList: podcastList && podcastList.users, loaded: false, totalCounts: podcastList.totalCounts });
  }

  async componentDidMount() {
    this.setState({ loaded: true });
    await this.getPodCastList();
    const awsCredentials = await this.props.getAwsCredentials()
    this.setState({ awsCredentials, loaded: false });
  }

  toggleModal = () => this.setState({
    isModalOpen: !this.state.isModalOpen,
    is_show: false, name: null, s3_audio_key: [],
    userId: null, user: '', inputValue: '', userLists: []
  });

  podcastMedia = (podcast) => {
    let count = 0;
    if (podcast && podcast.length) {
      podcast.forEach((element) => {
        count += element.medias.length;
      });
    }
    return count ? count : 'N/A';
  }

  columns = [
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
      cell: row => <div className="user-wrapper">
        <NavLink className="link-wrap" to={`/podcast-detail/${row.id}`}>
          <img height="33" width="33" alt={row.name} src={row.profile_image ? row.profile_image : DummyIcon} />
          <span className="user-detail">
            <span className="name">{row.name}</span>
            <span className="email-wrap">{row.email}</span>
          </span>
        </NavLink>
      </div>
    },
    {
      name: 'No. of Podcast(s)',
      selector: 'podcastno',
      center: true,
      cell: row => <div className="podcast-wrapper">
        <span className="name">{row.podcasts && row.podcasts.length}</span>
      </div>
    },
    {
      name: 'No. of Audios Files',
      selector: 'audiofiles',
      center: true,
      cell: row => <div className="podcast-wrapper">
        <span className="name">{this.podcastMedia(row.podcasts)}</span>
      </div>
    },
    {
      name: 'Action',
      selector: 'action',
      center: true,
      cell: row => <NavLink className="link-wrap" to={`/podcast-detail/${row.id}`}><span className="MaroomBtn">View</span></NavLink>
    }
  ];

  handleChange = e => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
    if (e.target.value === "") {
      e.target.classList.add('error');
    } else {
      e.target.classList.remove('error');
    }
  };

  handleSelect = (e, item) => {
    if (e) {
      this.setState({ user: item, userId: item.id, inputValue: item.value });
    }
  };

  searchInputChange = async (e) => {
    let inputValue = e.target.value
    this.setState({ inputValue }, async () => {
      const usersList = await this.props.searchUserByName(inputValue);
      if (usersList && usersList.data) {
        let userslist = [];
        usersList.data.length && usersList.data.forEach((user, key) => {
          userslist.push({
            value: user.name,
            id: user.id,
            label: <div className="list-with-icon" key={key}>
              <span className="img-wrap">
                <img className="small-icon" src={user.profile_image ? user.profile_image : DummyIcon} alt="red" height="30px" width="30px" />
              </span>
              <div className="user-info">
                <strong className="user-name">{user.name}</strong>
                <small className="email">{user.email}</small>
              </div>
            </div>
          })
        });
        this.setState({ userLists: userslist })
      }
    });

  }

  handleCustom = e => {
    this.setState({ is_show: !this.state.is_show });
  };

  createPodcast = async () => {
    const { name, userId, is_show, s3_audio_key, s3_audio_duration } = this.state;
    if (!name && !userId && s3_audio_key && !s3_audio_key.length) {
      return;
    } else if (!name) {
      toast('Please enter podcast name!', { type: 'error' });
      return;
    } else if (!userId) {
      toast('Please select a user!', { type: 'error' });
      return;
    } else if (s3_audio_key && !s3_audio_key.length) {
      toast('Please upload atleast one audio track!', { type: 'error' });
      return;
    }
    this.setState({ loaded: true });
    const data = {
      "name": name,
      "user_id": userId,
      "is_show": is_show,
      "audio_url": []
    }
    if (s3_audio_key && s3_audio_key.length) {
      s3_audio_key.forEach((url, key) => {
        data.audio_url.push({
          "url": `/${url}`,
          "duration": s3_audio_duration[key],
          "thumbnail": null,
          "title": url.split('/audio/')[1]
        })
      })
    }
    const create = await this.props.createPodcast(data);
    if (create && create.status === 201) {
      toast('Podcast created successfully!');
      this.setState({
        isModalOpen: false, name: '',
        userId: null, user: '', is_show: false, s3_audio_key: [],
        inputValue: '',
        userLists: []
      });
      this.setState({ loaded: true });
      this.getPodCastList();
    } else if (create && create.data) {
      toast(create.data, { type: 'error' });
    }
    this.setState({ loaded: false });
  }

  getDuration = (data) => {
    let audio = document.createElement('audio');
    audio.src = data.location;
    audio.addEventListener('loadedmetadata', () => {
      var duration = audio.duration;
      this.setState({
        s3_audio_duration: [...this.state.s3_audio_duration, `${parseInt(duration)}`]
      }, () => {
        return true;
      })
    }, false);
  }

  uploadAudioOns3 = async () => {
    this.setState({ loaded: true });
    const userId = this.state.userId
    const folder = `users/${userId}/audio`
    const { bucket_name, aws_access_key_id, aws_secret_access_key } = this.state.awsCredentials.s3
    const config = {
      bucketName: bucket_name,
      dirName: folder,
      region: 'us-east-1',
      accessKeyId: aws_access_key_id,
      secretAccessKey: aws_secret_access_key,
    }
    let fileObj = this.state.fileData[0]
    S3FileUpload
      .uploadFile(fileObj, config)
      .then(async (data) => {
        await this.getDuration(data);
        this.setState({
          s3_audio_key: [...this.state.s3_audio_key, `${data.key}`]
        })
        this.setState({ loaded: false });
      })
      .catch(err => err)
  }

  onFileChange = (file) => {
    const selectedFile = file.target.files[0];
    if (
      selectedFile.type.includes('wav') ||
      selectedFile.type.includes('mpeg') ||
      selectedFile.type.includes('mp3')
    ) {
      const date = new Date();
      Object.defineProperty(file.target.files[0], 'name', {
        writable: true,
        value: `${date.getTime()}_${file.target.files[0].name.replaceAll(' ', '')}`
      });
      this.setState({ fileData: file.target.files }, () => {
        if (!this.state.userId) {
          toast('Please select user!', { type: 'error' });
          this.setState({ fileData: null })
          return false;
        } else if (this.state.s3_audio_key && this.state.s3_audio_key.length >= 3) {
          toast('You can upload only three audio track in a podcast', { type: 'error' });
          return false;
        }
        else {
          this.uploadAudioOns3();
        }
      });
    } else {
      file.target.value = '';
      return toast('You can upload only mp3 or wav file', { type: 'error' });
    }
    return false;
  }

  removeTrack = (track) => {
    const newTrack = this.state.s3_audio_key.filter(e => e !== track);
    this.setState({ s3_audio_key: newTrack })
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber, loaded: true }, () => {
      this.getPodCastList(pageNumber);
    });
  }

  onSort = (e) => {
    this.setState({
      sort: !this.state.sort,
      sortBy: e.selector,
      loaded: true
    }, () => {
      this.getPodCastList(this.state.activePage, e.selector, this.state.sort);
    });
  }

  render() {
    const { name, is_show, audio_url, user, userLists,
      loaded, s3_audio_key, isDisabled, userId, InputSwitch,
      podcastsList, limit, totalCounts, activePage } = this.state;
    return (
      <LoadingMask loading={loaded} text={"loading..."}>
        <ToastContainer
          position="top-center"
          autoClose={Numbers.twoThou}
          transition={Zoom}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
        />
        <DashboardStructure title="Podcast Management" history={this.props.history}>
          <div className="header-section minus-space">
            <div className="create-podcast">
              <Button color="primary" onClick={this.toggleModal}>Create Podcast</Button>
              <ModalBox
                isOpen={this.state.isModalOpen}
                ModalTitle="Create Podcast"
                withHeader={true}
                toggle={this.toggleModal}
                className="modal-right">
                <PodcastForm name={name} user={user} audio_url={audio_url}
                  is_show={is_show} handleChange={this.handleChange}
                  handleSelect={this.handleSelect}
                  handleCustom={this.handleCustom}
                  searchInputChange={this.searchInputChange}
                  createPodcast={this.createPodcast}
                  userLists={userLists}
                  onFileChange={this.onFileChange}
                  s3_audio_key={s3_audio_key}
                  isDisabled={isDisabled}
                  userId={userId}
                  removeTrack={this.removeTrack}
                  InputSwitch={InputSwitch}
                  inputValue={this.state.inputValue}
                />
              </ModalBox>
            </div>
          </div>
          <DataTable
            title="Manage Podcasts"
            columns={this.columns}
            className="custom-table with-5-col"
            data={podcastsList}
            onSort={this.onSort}
          />
          {totalCounts > 0 && <Pagination
            activePage={activePage}
            itemsCountPerPage={limit}
            totalItemsCount={totalCounts}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
          />}
        </DashboardStructure>
      </LoadingMask>
    )
  }
}

const mapStateToProps = store => {
  return {
    admin: store.user
  }
}

export default connect(mapStateToProps, {
  userList,
  getAwsCredentials,
  createPodcast,
  getPodcastList,
  searchUserByName
})(withRouter(PodcastListing));
