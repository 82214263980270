import React from 'react';
import LoginStructure from '../../../components/shared/LoginStructure/LoginStructure';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import SuccessMessage from './SuccessMessage';
import { userResetPassword } from '../../../api/admin';
import { connect } from 'react-redux';
import LoadingMask from "react-loadingmask";
import 'react-toastify/dist/ReactToastify.css';
import { Numbers } from '../../../constants/constant'
import { withRouter } from 'react-router-dom';

import { ToastContainer, toast, Zoom } from 'react-toastify';

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      confirmPassword: '',
      isValid: false,
      onSuccess: false,
      loaded: false,
      userForgotData: true,
      isPasswordShow: false,
      isPasswordShowNew: false,
      token: '',
      isAdmin: '',
      isPasswordMatch: true,
      onError: false
    };
  }

  componentDidMount() {
    if (this.props && this.props.location) {
      const tok = (this.props.location.search).split('=');
      let token = tok[Numbers.one].split('&is_admin')[0]
      this.setState({ token: token, isAdmin: tok[Numbers.two] });
      localStorage.setItem('token', token);
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
  }

  validatePassword = (password = '') => {
    let regex = RegExp(/^(?=.*\d)(?=.*[a-zA-Z])[0-9a-zA-Z!@#$%^&*]{6,20}$/)
    if (regex.test(password)) return true
    return false
  }

  handleChange = e => {
    const { value, name } = e.target;
    this.setState({ [name]: value }, (er) => {
      if (this.state.newPassword !== "" && this.state.confirmPassword !== "") {
        if (this.state.newPassword === this.state.confirmPassword) {
          if (this.validatePassword(this.state.newPassword)) {
            this.setState({ isValid: true, isPasswordMatch: true });
          } else {
            this.setState({
              isPasswordMatch: false, isValid: false,
              passwordError: 'Password must be alphanumeric with min and max length 6 and 20!'
            });
          }
        } else {
          this.setState({
            isPasswordMatch: false, isValid: false,
            passwordError: 'Password does not match!'
          });
        }
      } else {
        this.setState({ isValid: false });
      }
    });
  };

  resetPassword = async () => {
    this.setState({ loaded: true });
    await this.props.userResetPassword(this.state.confirmPassword, this.state.isAdmin).then(res => {
      if (res) {
        localStorage.removeItem('token');
        toast("Your password has been changed successfully!");
        this.props.history.push(`/reset-password-confirmation?is-admin=${this.state.isAdmin}`);
      } else {
        this.setState({ onError: true })
      }
      this.setState({ userForgotData: res, loaded: false });
    });

  }

  showPassword = (flag) => {
    if (flag === 'new') {
      this.setState({ isPasswordShowNew: !this.state.isPasswordShowNew });
    } else {
      this.setState({ isPasswordShow: !this.state.isPasswordShow });
    }
  }

  textInputState = (isPasswordShowNew) => {
    if (isPasswordShowNew) {
      return 'text';
    }
    return 'password';
  }

  errorState = (isPasswordMatch) => {
    if (!isPasswordMatch) {
      return <span className="error">{this.state.passwordError}</span>
    }
    return null;
  }

  onTokenExpire = (onError) => {
    if (onError) {
      return <span className="error">Token expired! </span>
    }
    return null;
  }

  render() {
    let { onSuccess, isValid, loaded, isPasswordShow, isPasswordShowNew, newPassword, confirmPassword, isPasswordMatch, onError } = this.state
    return (
      <LoadingMask loading={loaded} text={"loading..."}>
        <LoginStructure>
          <ToastContainer
            position="top-center"
            autoClose={Numbers.twothou}
            transition={Zoom}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
          />
          {onSuccess && isValid ?
            <SuccessMessage /> :
            <div>
              <h2 className="form-title">Reset Your Password</h2>
              <p className="sub-text">Reset your password for your account.</p>
              <Form onSubmit={this.handleSubmit}>
                <FormGroup>
                  <Label for="examplePassword">New Password</Label>
                  <Input
                    type={this.textInputState(isPasswordShowNew)}
                    name="newPassword"
                    id="examplePassword"
                    value={newPassword}
                    onChange={this.handleChange}
                    placeholder="Enter password" />
                  <span className="show-pass" onClick={() => this.showPassword('new')}>Show</span>
                </FormGroup>
                <FormGroup>
                  <Label for="examplePasswordConfirm">Confirm Password</Label>
                  <Input
                    type={this.textInputState(isPasswordShow)}
                    name="confirmPassword"
                    id="examplePasswordConfirm"
                    value={confirmPassword}
                    onChange={this.handleChange}
                    placeholder="Enter password" />
                  <span className="show-pass" onClick={() => this.showPassword('confirm')}>Show</span>
                  {this.errorState(isPasswordMatch)}
                  {this.onTokenExpire(onError)}
                </FormGroup>
                <FormGroup>
                  <Button
                    color="primary"
                    className="wider"
                    onClick={this.resetPassword}
                    disabled={!this.state.isValid}
                  >
                    Reset Password
                  </Button>
                </FormGroup>
              </Form>
            </div>
          }
        </LoginStructure>
      </LoadingMask>
    )
  }
}

const mapStateToProps = store => {
  return {
    admin: store.user
  }
}

export default connect(mapStateToProps, {
  userResetPassword
})(withRouter(ResetPassword));
