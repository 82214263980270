import React from "react";
import * as Solid from "@heroicons/react/24/solid";
import Carousel from "react-elastic-carousel";
import PreloadImage from "react-preload-image";
import "./style.scss";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2, itemsToScroll: 2 },
  { width: 850, itemsToShow: 3 },
  { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
  { width: 1450, itemsToShow: 5 },
  { width: 1750, itemsToShow: 6 },
  { width: 2050, itemsToShow: 7 },
  { width: 2350, itemsToShow: 8 },
  { width: 2650, itemsToShow: 9 },
  { width: 2950, itemsToShow: 10 },
];

export const SongsFeatured = ({
  songsFeatured,
  featuredCurrent,
  handleFeatureCurrent,
}) => {
  return (
    <div className="songs-featured">
      {songsFeatured.length === 0 && (
        <div className="empty">Not added contest submission.</div>
      )}
      {songsFeatured.length > 0 && (
        <Carousel
          breakPoints={breakPoints}
          pagination={false}
          renderArrow={({ type, onClick, isEdge }) => {
            const pointer =
              type === "PREV" ? (
                <Solid.ChevronLeftIcon />
              ) : (
                <Solid.ChevronRightIcon />
              );
            return (
              <button
                className="arrow-slider"
                onClick={onClick}
                disabled={isEdge}
              >
                {pointer}
              </button>
            );
          }}
        >
          {songsFeatured.map((song, index) => {
            const active = index === featuredCurrent;
            return (
              <div key={index} className={`card-song ${active && "active"}`}>
                {!song.is_active && (
                  <div className="paused-votes">Paused votes</div>
                )}
                <div className="card-cover">
                  <span className="top">{index + 1}</span>
                  <PreloadImage
                    className="card-img"
                    src={song.media.thumbnail}
                    alt={song.media.title}
                    lazy
                  />
                  <div
                    className="play-song"
                    onClick={() => handleFeatureCurrent(index)}
                  >
                    {active ? (
                      <Solid.PauseIcon className="icon-play" />
                    ) : (
                      <Solid.PlayIcon className="icon-play" />
                    )}
                  </div>
                  <div className="count-votes">
                    <Solid.HeartIcon className="heart-icon" />
                    <span className="votes">
                      {song.votes}{" "}
                      {song.votes > 1 || song.votes === 0 ? (
                        <>votes</>
                      ) : (
                        <>vote</>
                      )}
                    </span>
                  </div>
                </div>
                <h3 className="card-title">{song.media.title}</h3>
              </div>
            );
          })}
        </Carousel>
      )}
    </div>
  );
};
