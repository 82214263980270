import axios from "axios";
import "./interceptors";

export function getSchedulerParlourList() {
  let url = `admin/scheduler-parlour/all`;

  return (dispatch) => {
    return axios
      .get(url)
      .then((res) => {
        dispatch({
          type: "GET_SCHEDULER_PARLOUR_LIST",
          data: res.data,
        });
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function deleteSchedulerParlour(parlourId) {
  let url = `admin/scheduler-parlour/delete/${parlourId}`;

  return (dispatch) => {
    return axios
      .delete(url)
      .then((res) => {
        dispatch({
          type: "DELETE_SCHEDULER_PARLOUR",
          data: res.data,
        });
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  };
}

export function getSchedulerParlourParticipants(parlourId) {
  const url = `admin/scheduler-parlour/${parlourId}/participants`;
  return (dispatch) => {
    return axios
      .get(url)
      .then((res) => res.data)
      .catch((err) => {
        throw err;
      });
  };
}