import React from 'react';
import LoginStructure from '../../../components/shared/LoginStructure/LoginStructure';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { userLogin } from '../../../api/admin';
import LoadingMask from "react-loadingmask";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isEmail: false,
      isPassword: false,
      loaded: false,
      isSuccess: false,
      isPasswordShow: false
    };
  }

  componentDidMount() {
    if (localStorage.getItem('token')) {
      this.props.history.push('/dashboard');
    }
  }

  handleSubmit = e => {
    e.preventDefault();
  }

  handleChange = e => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
    if (e.target.value === "") {
      e.target.classList.add('error');
    } else {
      e.target.classList.remove('error');
    }
  };

  userSignIn = async () => {
    this.setState({ loaded: true });
    const userData = await this.props.userLogin(this.state);
    this.setState({ loaded: false, isSuccess: true });
    if (userData) {
      localStorage.setItem('token', userData.data && userData.data.token);
      localStorage.setItem('userId', userData.data && userData.data.id);
      this.props.history.push('/dashboard');
    }
  }

  showPassword = () => {
    this.setState({ isPasswordShow: !this.state.isPasswordShow });
  }

  render() {
    const { loaded, isEmail, isPassword, isSuccess, password, email, isPasswordShow } = this.state;
    return (<>
      <LoadingMask loading={loaded} text={"loading..."}>
        <LoginStructure title="Sign In">
          <Form onSubmit={this.handleSubmit}>
            <FormGroup>
              <Label for="exampleEmail">Email Address</Label>
              <Input
                type="email"
                name="email"
                value={email}
                id="exampleEmail"
                placeholder="Enter your email address"
                onChange={this.handleChange} />
              {!email && isEmail ? <span className="error">Invalid email address!</span> : null}
            </FormGroup>
            <FormGroup>
              <Label for="examplePassword">Password</Label>
              <Input
                type={isPasswordShow ? 'text' : 'password'}
                name="password"
                value={password}
                id="examplePassword"
                placeholder="Enter your password"
                onChange={this.handleChange} />
              <span className="show-pass" onClick={this.showPassword}>{isPasswordShow ? 'Hide' : 'Show'}</span>
              {!password && isPassword ? <span className="error">Invalid password!</span> : null}
              {isSuccess ? <span className="error">Invalid email or password!</span> : null}
            </FormGroup>
            <FormGroup className="text-right">
              <Link
                className="text-link"
                to="/forgot-password">
                Forgot password?
            </Link>
            </FormGroup>
            <FormGroup>
              <Button
                color="primary"
                className="wider"
                disabled={email && password ? false : true}
                onClick={this.userSignIn}
              >
                Sign In
            </Button>
            </FormGroup>
          </Form>
        </LoginStructure>
      </LoadingMask>
    </>)
  }
}

const mapStateToProps = store => {
  return {
    admin: store.user
  }
}

export default connect(mapStateToProps, {
  userLogin
})(withRouter(Login));
