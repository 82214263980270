import React from 'react'
import {
  Route,
  Redirect,
  withRouter
} from 'react-router-dom'

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  const token = localStorage.getItem('token');
  return (
    <Route {...rest} render={(props) => (
      token
        ? <Component {...props} />
        : <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }} />
    )} />
  )
}

export default withRouter(PrivateRoute);
