import React from 'react';
import { Line } from 'react-chartjs-2';


const LineChart = ({ chartData }) => {
  return (
    <div>
      <Line
        width={800}
        height={225}
        data={chartData}
        options={{
          maintainAspectRatio: false,
          defaultFontColor: '#757b80',
          legend: {
            display: false
          },
          scales: {
            yAxes: [
              {
                gridLines: {
                  color: '#ededed',
                  lineWidth: 1
                },
                ticks: {
                  suggestedMin: 0,
                  // suggestedMax: 25,
                  // stepSize: 5,
                  padding: 14,
                  fontSize: 11,
                  fontFamily: 'nunito_sansregular',
                  fontColor: '#757b80',
                  // Include a dollar sign in the ticks
                  callback: function (value, index, values) {
                    return '$' + value;
                  }
                }
              }
            ],
            xAxes: [{
              gridLines: {
                display: false
              },
              ticks: {
                fontSize: 14,
                fontFamily: 'nunito_sansregular',
                fontColor: '#757b80'
              }
            }]
          },
          tooltips: {
            backgroundColor: '#f1f1f1',
            titleFontColor: '#6d7278',
            bodyFontColor: '#000',
            caretPadding: 6,
            displayColors: false,
            titleFontSize: 12,
            bodyFontSize: 14,
            xPadding: 10,
            yPadding: 10,
            callbacks: {
              labelColor: function (tooltipItem, chart) {
                return {
                  borderColor: '#921950',
                  backgroundColor: '#921950'
                };
              },
              labelTextColor: function (tooltipItem, chart) {
                return '#000';
              },
              title: function (tooltipItem, data) {
                return data['datasets'][0]['date'][tooltipItem[0]['index']];
              },
              label: (tooltipItem, data) => {
                return `$${tooltipItem.value}`
              }
            }
          }
        }}
      />
    </div>
  )
}
export default LineChart;
